import { isDefined } from "@clipboard-health/util-ts";
import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonList,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { CircularProgress, Stack } from "@mui/material";
import { useToast } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { ShiftSearchMode } from "@src/appV2/OpenShifts/ShiftAction/api/useClaimShift";
import { useGetPaginatedBookabilityStatusForShifts } from "@src/appV2/OpenShifts/ShiftAction/api/useGetBookabilityStatusForShifts";
import { logApiFailureEvent } from "@src/lib/analytics";
import { Shift } from "@src/lib/interface/src";
import { chevronBackOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { getOpenNegotiatingShifts } from "./api";
import { IRateNegotiationsListProps } from "./interfaces";
import { RateNegotiatingShiftsCard } from "./NegotiatingShiftsCard";
import { ShiftPaginationLoader } from "../dayView/shiftItem/components/ShiftPagination";
import { useSession } from "../store/helperHooks";

export function RateNegotiationsList(props: IRateNegotiationsListProps) {
  const { onClose } = props;

  const [negotiationShifts, setNegotiationShifts] = useState<Shift[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [foundDeepLinkNegotiation, setFoundDeepLinkNegotiation] = useState<boolean>(true);

  const { agent } = useSession();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const deepLinkNegotiationId = searchParams.get("negotiationId") ?? props.deepLinkNegotiationId;
  const { showErrorToast } = useToast();

  const fetchNegotiatingShifts = async () => {
    try {
      setLoading(true);
      const negotiatingShifts = await getOpenNegotiatingShifts();
      setNegotiationShifts(negotiatingShifts);
    } catch (error) {
      logApiFailureEvent(error);
      showErrorToast("Error while getting negotiating shifts.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNegotiatingShifts();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deepLinkNegotiationId) {
      const foundNegotiation = negotiationShifts.some((negotiationShift) => {
        return negotiationShift.rateNegotiation?._id === deepLinkNegotiationId;
      });
      setFoundDeepLinkNegotiation(foundNegotiation);
    }
  }, [negotiationShifts, deepLinkNegotiationId]);

  const negotiatingShiftIds = negotiationShifts
    .filter((negotiationShift) => isDefined(negotiationShift._id))
    .map((negotiationShift) => negotiationShift._id ?? "");

  const {
    shiftBookabilityById,
    queryResult: paginatedBookabilityStatusQueryResult,
    bookabilityLoadedShiftsCount: shiftsToRenderCount,
  } = useGetPaginatedBookabilityStatusForShifts({
    shiftIds: negotiatingShiftIds,
    agentId: agent?.userId ?? "",
  });

  const {
    isLoading: isLoadingShiftBookability,
    fetchNextPage: fetchNextPageBookabilityStatus,
    isFetchingNextPage: isFetchingShiftBookabilityForNextPage,
    hasNextPage: hasNextOpenShiftPage,
  } = paginatedBookabilityStatusQueryResult;

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar className="relative" mode="ios">
          <IonButtons align-center>
            {isDefined(onClose) ? (
              <IonButton routerDirection="back" fill="clear" color="light" onClick={onClose}>
                <IonIcon icon={chevronBackOutline} mode="ios" size="large" />
              </IonButton>
            ) : (
              <IonBackButton text="" defaultHref="/home/myShifts" mode="ios" />
            )}
          </IonButtons>
          <IonTitle>Shifts in Negotiation</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonLoading isOpen={loading} backdropDismiss={true} data-testid="negotiations-list-loader" />
      <IonContent>
        <IonList lines="none">
          {negotiationShifts.map((negotiationShift, shiftIndex) => {
            const shiftBookability =
              shiftBookabilityById[negotiationShift._id ?? ""]?.attributes.bookability;

            if (isDefined(shiftsToRenderCount) && shiftIndex >= shiftsToRenderCount) {
              return null;
            }
            return (
              <>
                {hasNextOpenShiftPage &&
                  isDefined(fetchNextPageBookabilityStatus) &&
                  !isFetchingShiftBookabilityForNextPage &&
                  shiftIndex + 1 === shiftsToRenderCount && (
                    <ShiftPaginationLoader
                      onLoadNextPage={() => {
                        logEvent(APP_V2_USER_EVENTS.VIEWED_NEXT_NEGOTIATION_SHIFT_PAGE, {
                          searchMode: ShiftSearchMode.NEGOTIATING_SHIFTS,
                          shiftsToRenderCount,
                        });

                        fetchNextPageBookabilityStatus();
                      }}
                    />
                  )}
                <RateNegotiatingShiftsCard
                  negotiationShift={negotiationShift}
                  fetchNegotiatingShifts={fetchNegotiatingShifts}
                  shouldOpenNegotiationTool={
                    !!(
                      deepLinkNegotiationId &&
                      negotiationShift.rateNegotiation?._id === deepLinkNegotiationId
                    )
                  }
                  key={negotiationShift._id}
                  shiftBookability={shiftBookability}
                  isLoadingShiftBookability={isLoadingShiftBookability}
                />
              </>
            );
          })}
          {isFetchingShiftBookabilityForNextPage && (
            <Stack
              sx={{
                alignItems: "center",
                paddingY: 1,
              }}
            >
              <CircularProgress />
            </Stack>
          )}
        </IonList>
      </IonContent>
      <IonAlert
        mode="ios"
        isOpen={!foundDeepLinkNegotiation && !loading}
        header="Negotiation not found"
        message="Sorry, that notification cannot be found in our system. You can try to find it in My Shifts, but negotiations are only visible for a limited time after they end."
        backdropDismiss={false}
        buttons={[
          {
            text: "OK",
            handler: () => setFoundDeepLinkNegotiation(true),
          },
        ]}
      />
    </IonPage>
  );
}
