import { LoadingButton } from "@clipboard-health/ui-react";
import { IonCol, IonLabel, IonRow } from "@ionic/react";
import { Button } from "@mui/material";
import { BookabilityAttributes } from "@src/appV2/OpenShifts/ShiftAction";
import { ShiftSearchMode } from "@src/appV2/OpenShifts/ShiftAction/api/useClaimShift";
import {
  BookabilityNegotiationMetadata,
  ClaimShiftResponse,
} from "@src/appV2/OpenShifts/ShiftAction/types";
import BlueCheckSvg from "@src/assets/icons/blue-check.svg";
import { Shift } from "@src/lib/interface/src";

import {
  NegotiationCommitType,
  NegotiationRateRole,
  NegotiationStatus,
  RateNegotiationHistoryResponse,
} from "./interfaces";
import { ShiftActionButton } from "../ShiftAction";

interface Props {
  shift: Shift;
  negotiationHistory: RateNegotiationHistoryResponse[];
  workApproved: boolean;
  closeModal: () => void;
  isWorkerProposing: boolean;
  showCommitModal: (_: any) => void;
  pageLoading: boolean;
  isLoadingShiftBook?: boolean;
  proposeButtonText: string;
  onClickProposeButton: () => void;
  handleEndNegotiation: () => Promise<void>;
  shiftBookability?: BookabilityAttributes;
  bookabilityNegotiationMetadata: BookabilityNegotiationMetadata;
  onBookShift: (shift: ClaimShiftResponse) => void;
}

export function NegotiationToolFooter(props: Props) {
  const {
    shift,
    negotiationHistory,
    workApproved,
    closeModal,
    isWorkerProposing,
    showCommitModal,
    pageLoading,
    isLoadingShiftBook,
    proposeButtonText,
    onClickProposeButton,
    handleEndNegotiation,
    shiftBookability,
    bookabilityNegotiationMetadata,
    onBookShift,
  } = props;

  const negotiationIsBookable = negotiationHistory.some((historyItem) => {
    return historyItem.createdByUserRole === NegotiationRateRole.WORKPLACE;
  });

  if (workApproved || shift?.rateNegotiation?.status === NegotiationStatus.ACCEPTED) {
    return (
      <IonRow>
        <IonCol size="12" className="shift-item__work-confirmed">
          <IonLabel className="ion-label">
            <img src={BlueCheckSvg} alt="" />
            You&apos;re Working This Shift!
          </IonLabel>
        </IonCol>
      </IonRow>
    );
  }

  if (shift?.rateNegotiation?.status && shift?.rateNegotiation?.status !== NegotiationStatus.OPEN) {
    return (
      <Button variant="outlined" fullWidth onClick={closeModal}>
        Close
      </Button>
    );
  }

  return (
    <>
      {negotiationIsBookable && !isWorkerProposing && (
        <ShiftActionButton
          shiftBookability={shiftBookability}
          shift={shift}
          searchMode={ShiftSearchMode.NEGOTIATING_SHIFTS}
          isUrgentShift={false}
          isPriorityShift={false}
          isShiftBookabilityLoading={pageLoading || Boolean(isLoadingShiftBook)}
          negotiationMetadata={bookabilityNegotiationMetadata}
          onBookShift={onBookShift}
          // we don't allow mark interests with negotiations
          onShiftMarkInterest={() => {}}
        />
      )}
      {isWorkerProposing && (
        <LoadingButton
          size="small"
          fullWidth
          variant={isWorkerProposing ? "outlined" : "contained"}
          sx={{
            color: (theme) =>
              isWorkerProposing ? theme.palette.success.main : theme.palette.common.white,
            borderColor: (theme) =>
              isWorkerProposing ? theme.palette.success.main : theme.palette.primary.main,
          }}
          data-testid="negotiation-propose-btn"
          onClick={onClickProposeButton}
          isLoading={pageLoading || Boolean(isLoadingShiftBook)}
        >
          {proposeButtonText}
        </LoadingButton>
      )}
      {shift?.rateNegotiation?.status === NegotiationStatus.OPEN && (
        <Button
          size="small"
          variant="outlined"
          sx={{
            color: (theme) => theme.palette.error.main,
            borderColor: (theme) => theme.palette.error.main,
          }}
          fullWidth
          onClick={() => {
            showCommitModal({
              isOpen: true,
              onCommit: handleEndNegotiation,
              modalType: NegotiationCommitType.END_NEGOTIATION,
            });
          }}
        >
          End Negotiation
        </Button>
      )}
    </>
  );
}
